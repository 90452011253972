import React from 'react'

const Layout = () => {
  return (
    <div>
      {/* <h1> hmmm...</h1> */}
    </div>
  )
}

export default Layout
